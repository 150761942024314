<priva-stepped-dialog
    class="about-dialog__dialog"
    type="dynamic"
    alignment="center"
    title="Hints"
    [subTitle]=""
    [steps]="steps"
    [activeStep]="activeStep"
    (buttonClick)="handleButtonClick($event)"
    (close)="close()"
    (backdropClick)="onBackdropClick($event)"
>
    @if (activeStep === 0) {
        <img
            class="priva-dialog-intro__image priva-dialog-intro__1"
            alt="Introduction to Device Templates"
            src="/assets/images/intro_DT.png"
        />
    }
    @if (activeStep === 1) {
        <img
            class="priva-dialog-intro__image priva-dialog-intro__2"
            alt="Introduction to Connecting Devices"
            src="/assets/images/intro_CD.png"
        />
    }
</priva-stepped-dialog>
