/* eslint-disable no-async-promise-executor */
import { inject, Injectable } from '@angular/core';

import { FirmwareApiPaths } from 'app/configuration';
import { randomNumber } from 'app/core/utils';

@Injectable({
    providedIn: 'root',
})
export class PlatformService {
    private readonly firmwareApiPaths: FirmwareApiPaths = inject(FirmwareApiPaths);
    private isInitialized = false;
    private _isOnline = false;

    public async initialize(): Promise<void> {
        await this.isOnlineCheck();
        this.isInitialized = true;
    }

    public async isOnlineCheck(): Promise<boolean> {
        const url = `${this.firmwareApiPaths.ping()}/?rand=${this.getRandomString()}`;
        try {
            const response = await this.fetchTimeout(url.toString(), { method: 'HEAD' });
            this._isOnline = response.ok;
            return response.ok;
        } catch {
            // WvK: instead of a literal true, use (browser)navigator.onLine for now.
            // This is still suboptimal, as sometimes it results in a true even when the internet is not reachable.
            // This occurs e.g. when a request is made to a local device.
            // Eventually, this needs to be replaced by a check to a Priva endpoint that is not frequently re-deployed,
            // like the current Firmware service.
            this._isOnline = navigator.onLine;
            return this.isOnline;
        }
    }

    public get isOnline(): boolean {
        //this.assertInitialized();
        return this._isOnline;
    }

    private getRandomString() {
        return randomNumber().toString(36).substring(2, 15);
    }

    private fetchTimeout(url: string, options: RequestInit, timeout = 1000): Promise<Response> {
        return new Promise((resolve, reject) => {
            fetch(url, options).then(resolve, reject);
            setTimeout(reject, timeout);
        });
    }

    private assertInitialized() {
        if (!this.isInitialized) {
            throw new Error('First initialize the PlatformService');
        }
    }
}
